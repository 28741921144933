import { httpRequest } from '@/request';
import { getServerUrl } from '@/request/config';

export default {
  // 获取我能兑换商品列表
  getListCanExchange: ({ CountryCode, X_Language, root, endPoints, startPoints, pageNumber, pageSize, instaToken }) => {
    return httpRequest({
      url: getServerUrl('POINT', '/point/service/mall/product/listCanExchange', root),
      CountryCode,
      X_Language,
      instaToken,
      params: {
        endPoints,
        startPoints,
        pageNumber,
        pageSize,
      },
    });
  },

  // 获取热门商品
  getHotProductList: ({ CountryCode, X_Language, root, endPoints, startPoints, pageNumber, pageSize }) => {
    return httpRequest({
      url: getServerUrl('POINT', '/point/service/mall/product/listPopularProduct', root),
      CountryCode,
      X_Language,
      params: {
        endPoints,
        startPoints,
        pageNumber,
        pageSize,
      },
    });
  },
  // 获取分类列表
  getProductSort: ({ CountryCode, X_Language, root }) => {
    return httpRequest({
      url: getServerUrl('POINT', '/point/service/mall/product/listCategory', root),
      CountryCode,
      X_Language,
    });
  },
  // 获取产品列表
  getProductList: ({
    productCategoryId,
    CountryCode,
    X_Language,
    root,
    endPoints,
    startPoints,
    pageNumber,
    pageSize,
  }) => {
    return httpRequest({
      url: getServerUrl('POINT', '/point/service/mall/product/listProductByCategory', root),
      CountryCode,
      X_Language,
      params: {
        productButtonType: 'enabled_button',
        productCategoryId,
        endPoints,
        startPoints,
        pageNumber,
        pageSize,
      },
    });
  },
  // 获取榜单
  getEveryoneExchange: ({ CountryCode, X_Language, root }) => {
    return httpRequest({
      url: getServerUrl('POINT', '/point/service/mall/product/listEveryoneExchange', root),
      CountryCode,
      X_Language,
    });
  },
  // 获取用户头像
  getUserAvatarList: ({ root, CountryCode, X_Language }) => {
    return httpRequest({
      url: getServerUrl('POINT', '/point/service/mall/user/listEveryoneCashing', root),
      CountryCode,
      X_Language,
    });
  },

  getTotalPoints: ({ root, token }) => {
    return httpRequest({
      url: getServerUrl('POINT', '/point/account/getTotalPoints', root),
      headers: {
        'X-User-Token': token,
      },
    });
  },
};
